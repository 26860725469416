import { extendTheme, ThemeConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

import { ButtonStyle as Button } from "./components/ButtonStyle";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const theme = extendTheme({
  styles: {
    global: (props) => ({
      body: {
        bg: mode("gray.50", "gray.800")(props),
        color: mode("gray.700", "gray.200")(props),
      },
    }),
  },
  components: {
    Button,
  },
  fonts: {
    body: "Poppins",
    custom: "'Press Start 2P'",
  },
  config,
});
export default theme;
