import { mode } from "@chakra-ui/theme-tools";

export const ButtonStyle = {
  // style object for base or default style
  baseStyle: {},

  // styles for different sizes ("sm", "md", "lg")
  sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {
    header: (props) => ({
      color: mode("gray.600", "gray.200")(props),
      _hover: {
        color: mode("gray.700", "gray.50")(props),
      },
    }),
  },
  // default values for `size` and `variant`
  defaultProps: {},
};
